<template>
  <main class="login-page">
    <v-row class="login-page__main-row">
      <v-col md="3" lg="2" offset-md="3" offset-lg="2">
        <v-row class="flex-column">
          <v-row class="login-page__logo-row">
            <img src="/images/universkin-logo.svg" alt="Universkin Logo" />
          </v-row>
          <v-row>
            <v-container fluid>
              <slot></slot>
            </v-container>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
  </main>
</template>

<script>
export default {
  name: 'LoginPageTemplate'
};
</script>

<style lang="scss" scoped>
.login-page {
  height: 100vh;

  background-image: url('/images/background-image.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__main-row {
    padding-top: 12.5%;
  }

  &__logo-row {
    margin-left: 10px;
    margin-bottom: 58px;
  }
}
</style>
